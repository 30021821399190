export const PATIENT_LIST = '/u/patients';
export const PATIENT_CREATE = '/u/patients/create';
export const PATIENT_DETAIL = '/u/patients/:id([a-z0-9-]{36})';
export const PATIENT_DETAIL_RAW = '/u/patients/:id';
export const PATIENT_EDIT = '/u/patients/:id/edit';
export const PATIENT_DELETE = '/u/patients/:id/delete';
export const PATIENT_RECOVER_PASSWORD = '/u/patients/:id/recover-password';
export const PATIENT_TEMPORARY_IMAGES_UPLOAD = '/u/patients/:id/temporary-images/upload';

export const DIAGNOSTIC_REPORT_REQUEST = '/u/practitioner/diagnostic-report-request';
export const DIAGNOSTIC_REPORT_SENT = '/u/practitioner/diagnostic-report-sent';
export const QUICK_DIAGNOSTIC_REPORT_REQUEST = '/u/practitioner/quick-diagnostic-report-request';
export const DIAGNOSTIC_REPORT_RESULT =
  '/u/patients/:patientId/diagnostic-report/:diagnosticReportId';
export const DIAGNOSTIC_REPORTS = '/u/diagnostic-reports';
export const WORK_LIST = '/u/practitioner/work-list';
export const MONTHLY_ACTIVITY = '/u/practitioner/activity';
export const DAILY_ACTIVITY = '/u/practitioner/activity/:date';

export const EVENT_CREATE = '/u/practitioner/events/create';
export const EVENT_EDIT = '/u/practitioner/events/:id(\\d+)';
export const EVENT_EDIT_RAW = '/u/practitioner/events/:id';

export const COMPANY_DETAIL = '/u/company';
export const COMPANY_UPDATE_PAYMENT_METHOD = '/u/company/subscription-plan/update-payment-method';
export const COMPANY_METRICS = '/u/company/metrics';
export const INACTIVE_COMPANY = '/u/company/inactive-company';
export const PATHOLOGY_CUSTOMIZATION_LIST = '/u/company/pathology-customization';
export const PATHOLOGY_CUSTOMIZATION_EDIT = '/u/company/pathology-customization/:id(\\d+)/edit';
export const PATHOLOGY_CUSTOMIZATION_EDIT_RAW = '/u/company/pathology-customization/:id/edit';
export const PATHOLOGY_CUSTOMIZATION_CREATE = '/u/company/pathology-customization/create';
export const PATHOLOGY_OVERRIDE_DELETE = '/u/company/pathology-overrides/:id(\\d+)/delete';
export const PATHOLOGY_OVERRIDE_DELETE_RAW = '/u/company/pathology-overrides/:id/delete';

export const PATIENT_PAYMENT_ORDER_LIST = '/u/practitioner/patient-payment-orders';
export const PATIENT_PAYMENT_ORDER_CREATE = '/u/practitioner/patient-payment-orders/create';

export const PRACTICIONER_LIST = '/u/company/practitioners';
export const PRACTICIONER_CREATE = '/u/company/practitioners/create';
export const PRACTICIONER_EDIT = '/u/company/practitioners/:id([a-z0-9-]{36})';
export const PRACTICIONER_EDIT_RAW = '/u/company/practitioners/:id';
export const PRACTICIONER_DELETE = '/u/company/practitioners/:id/delete';
export const PRACTICIONER_DELETE_RAW = '/u/company/practitioners/:id/delete';
export const PRACTICIONER_RECOVER_PASSWORD = '/u/practitioners/:id/recover-password';
export const PRACTICIONER_RECOVER_PASSWORD_RAW = '/u/practitioners/:id/recover-password';

export const MEDICATION_REQUEST_CREATE = '/u/practitioner/medication-requests/create';
export const MEDICATION_REQUEST_DELETE =
  '/u/practitioner/medication-requests/:medicationRequestId/delete';
export const MEDICATION_REQUEST_EDIT =
  '/u/practitioner/medication-requests/:medicationRequestId/edit';

const paths = {
  INACTIVE_COMPANY,
  PATIENT_PAYMENT_ORDER_LIST,
  PATIENT_PAYMENT_ORDER_CREATE,
  DIAGNOSTIC_REPORT_REQUEST,
  MONTHLY_ACTIVITY,
  DAILY_ACTIVITY,
  DIAGNOSTIC_REPORT_SENT,
  QUICK_DIAGNOSTIC_REPORT_REQUEST,
  WORK_LIST,
  PATIENT_LIST,
  PATIENT_CREATE,
  PATIENT_DETAIL,
  PATIENT_EDIT,
  PATIENT_DELETE,
  PATIENT_RECOVER_PASSWORD,
  PATIENT_TEMPORARY_IMAGES_UPLOAD,
  DIAGNOSTIC_REPORT_RESULT,
  EVENT_CREATE,
  EVENT_EDIT,
  COMPANY_DETAIL,
  COMPANY_METRICS,
  COMPANY_UPDATE_PAYMENT_METHOD,
  PATHOLOGY_CUSTOMIZATION_CREATE,
  PATHOLOGY_CUSTOMIZATION_LIST,
  PATHOLOGY_OVERRIDE_DELETE,
  PATHOLOGY_CUSTOMIZATION_EDIT,
  PRACTICIONER_LIST,
  PRACTICIONER_CREATE,
  PRACTICIONER_EDIT,
  PRACTICIONER_DELETE,
  PRACTICIONER_RECOVER_PASSWORD,
  MEDICATION_REQUEST_CREATE,
  MEDICATION_REQUEST_DELETE,
  MEDICATION_REQUEST_EDIT,
  DIAGNOSTIC_REPORTS
};

export default paths;
